import React from 'react';
import { Popup } from 'react-mapbox-gl';

const FloodPopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    SECT_NAME: name,
    CROSS_ROAD,


  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="flood popup-body">
        <h4>{name}</h4>
        {CROSS_ROAD && <div>จุดตัดถนน: {CROSS_ROAD}</div>}
      </div>
    </Popup>
  );
}
export default FloodPopup;