import React, { Fragment, useState } from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { colors } from '@utils/colors';

const LineLayer = ({
  id,
  source,
  filter,
  lineWidth = 2,
  lineColor = colors.primary,
  show = true,
}) => {
  const layerId = `${id}-marker`;
  return (
    <Fragment>
      <Source id={id} geoJsonSource={{ type: 'geojson', data: source }} />
      <Layer
        id={layerId}
        type="line"
        sourceId={id}
        layout={{
          visibility: show ? 'visible': 'none',
        }}
        paint={{
          "line-width": lineWidth,
          "line-color": lineColor,
        }}
        filter={filter}
      />
    </Fragment>
  );
};
export default LineLayer;