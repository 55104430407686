import React, { Fragment, useState } from 'react';
import { Layer, Source } from 'react-mapbox-gl';

const PointLayer = ({
  id,
  source,
  icon,
  filter,
  show = true,
  onClick,
}) => {
  const layerId = `${id}-marker`;
  return (
    <Fragment>
      <Source id={id} geoJsonSource={{ type: 'geojson', data: source }} />
      <Layer
        id={layerId}
        type="symbol"
        sourceId={id}
        layout={show ? {
          "icon-image": icon,
          "icon-allow-overlap": true,
        } : {}}
        filter={filter}
        onClick={e => {
          const map = e.target;
          const features = map.queryRenderedFeatures(e.point, { layers: [layerId] });
          if (features.length > 0) {
            if (onClick) onClick(features[0]);
          }
        }}
      />
    </Fragment>
  );
};
export default PointLayer;