import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { HorizontalBar } from 'react-chartjs-2';

import population from '@assets/data/population.json';

import './PopulationDashboard.scss';

const PopulationDashboard = ({ district }) => {
  const [data, setData] = useState(null);

  const getVoter = (data) => {
    const cols = ['genz_votable_m', 'genz_votable_f', 'geny_m', 'geny_f', 'genx_m', 'genx_f', 'boomer_m', 'boomer_f', 'silent_m', 'silent_f'];
    return cols.reduce((sum, col) => (sum + data[col]), 0);
  }

  const getTotal = (data) => {
    const cols = ['all_m', 'all_f'];
    return cols.reduce((sum, col) => (sum + data[col]), 0);
  }

  // const getGenderChart = (data) => {
  //   const total = getTotal(data);
  //   return {
  //     data: {
  //       datasets: [{
  //         data: [
  //           (parseInt(data.all_m) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           (parseInt(data.all_f) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //         ],
  //         backgroundColor: [ 'rgba(54, 162, 235, 0.8)', 'rgba(255, 99, 132, 0.8)' ],
  //         label: 'Gender Data'
  //       }],
  //       labels: [ 'ชาย', 'หญิง' ]
  //     },
  //     options: {
  //       responsive: true,
  //       legend: {
  //         position: 'top',
  //       },
  //       title: {
  //         display: true,
  //         text: 'ชาย / หญิง'
  //       },
  //       animation: {
  //         animateScale: true,
  //         animateRotate: true
  //       },
  //       plugins: {
  //         datalabels: {
  //           color: 'white',
  //           font: {
  //             weight: 'bold'
  //           },
  //           formatter: function(x) {
  //             return x + '%'
  //           }
  //         }
  //       },
  //     }  
  //   }
  // }

  // const getGenerationDoughnutChart = data => {
  //   const total = getTotal(data);
  //   return {
  //     data: {
  //       datasets: [{
  //         data: [
  //           ((data.alpha_m           + data.alpha_f) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           ((data.genz_unvotable_m  + data.genz_unvotable_f ) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           ((data.genz_votable_m    + data.genz_votable_f   ) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           ((data.geny_m            + data.geny_f)   * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           ((data.genx_m            + data.genx_f)   * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           ((data.boomer_m          + data.boomer_f) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //           ((data.silent_m          + data.silent_f) * 100 / total).toLocaleString('en-US', {maximumFractionDigits: 2}),
  //         ],
  //         backgroundColor: [
  //           'rgb(255, 99, 132)',
  //           'rgb(255, 159, 64)',
  //           'rgb(255, 205, 86)',
  //           'rgb(75, 192, 192)',
  //           'rgb(54, 162, 235)',
  //           'rgb(153, 102, 255)',
  //           'rgb(201, 203, 207)'
  //         ],
  //         label: 'Gender Data'
  //       }],
  //       labels: [
  //         'Alpha (<7)',
  //         'Gen Z (8-17)',
  //         'Gen Z voting age (18-23)',
  //         'Gen Y (24-39)',
  //         'Gen X (40-55)',
  //         'Boomers (56-74)',
  //         'Silent (>75)'
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       legend: {
  //         position: 'top',
  //       },
  //       title: {
  //         display: true,
  //         text: 'แยกตามอายุ'
  //       },
  //       animation: {
  //         animateScale: true,
  //         animateRotate: true
  //       },
  //       plugins: {
  //         datalabels: {
  //           color: 'white',
  //           font: {
  //             weight: 'bold'
  //           },
  //           formatter: function(x) {
  //             return x + '%'
  //           }
  //         }
  //       },
  //     }
  //   }
  // }

  const getGenerationBarChart = data => {
    return {
      data: {
        labels: [
          'Alpha (<7)',
          'Gen Z (8-17)',
          'Gen Z voting age (18-23)',
          'Gen Y (24-39)',
          'Gen X (40-55)',
          'Boomers (56-74)',
          'Silent (>75)'
        ],
        datasets: [{
          label: 'ชาย',
          backgroundColor: 'rgba(54, 162, 235, 0.8)',
          data: [
            data.alpha_m,
            data.genz_unvotable_m,
            data.genz_votable_m,
            data.geny_m,
            data.genx_m,
            data.boomer_m,
            data.silent_m,
          ]
        }, {
          label: 'หญิง',
          backgroundColor: 'rgba(255, 99, 132, 0.8)',
          data: [
            data.alpha_f,
            data.genz_unvotable_f,
            data.genz_votable_f,
            data.geny_f,
            data.genx_f,
            data.boomer_f,
            data.silent_f,
          ]
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          // text: line[0]
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true
          }]
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: function(context) {
              return context.dataset.data[context.dataIndex] > 15;
            },
            font: {
              weight: 'bold'
            },
            formatter: function(x) {
              return x.toLocaleString(null, {minimumFractionDigits: 2})
            }
          }
        },
      }
    }
  }

  useEffect(() => {
    const p = population.find(d => d.dname === district?.label) || population[0];
    p.voter = getVoter(p);
    p.total = getTotal(p);
    p.voterPercentage = p.voter * 100 / p.total;
    // p.genderChart = getGenderChart(p);
    // p.generationDoughnutChart = getGenerationDoughnutChart(p);
    p.generationBarChart = getGenerationBarChart(p);
    setData(p);
  }, [district]);

  return (
    <div className="population-dashboard">
      { data && (
        <div>
          <div>
            <strong>
              ผู้สิทธิเลือกตั้ง {data.voter.toLocaleString('en-US', {maximumFractionDigits: 0})} คน
              ({data.voterPercentage.toLocaleString('en-US', {maximumFractionDigits: 0})}% ของประชากรทั้งเขต)
            </strong>
          </div>
          <Container fluid className="generation-bar-container">
            <HorizontalBar {...data.generationBarChart}/>
          </Container>
        </div>
      )}
    </div>
  )
}
export default PopulationDashboard;