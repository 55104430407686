import React from 'react';
import { Popup } from 'react-mapbox-gl';

const SchoolPopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    // General
    id,
    name,
    address,
    location,
    tel,
    dcode,

    // School
    id_sch,
    num_stu,

    // College
    num_voe,
    num_dip,
    employee,

    // University
    fax,
    web,

    // Daycare
    care_id,

    // Pre-center
    id_pre,
    num_teache,
    num_boy,
    num_girl,

    // Community Library
    Id_library,
    loca_type,

  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="school popup-body">
        <h4>{name}</h4>
        <div>ที่อยู่: {address || location || '-'}</div>
        <div>โทร: {tel || ''}</div>
        
        <div>{num_stu && `จำนวนนักเรียน: ${num_stu}`}</div>
      </div>
    </Popup>
  );
}
export default SchoolPopup;