import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Doughnut, HorizontalBar } from 'react-chartjs-2';

import doughnutData from '@assets/data/budget/doughnut.json';
import barData from '@assets/data/budget/bar.json';

import { chartColors } from '@utils/colors';
import { budgetCategories, expenseCategories } from '@utils/datasets';

import './BudgetDashboard.scss';

const BudgetDashboard = ({ district }) => {
  const districtDoughnut = doughnutData.find(d => `เขต${d.district}` === district?.label || district?.label === d.district);
  const districtBar = barData.find(d => `เขต${d.district}` === district?.label || district?.label === d.district);
  
  // if (!districtBar || !districtDoughnut) return null;

  const doughnut = {
    data: {
      datasets: [{
        data: districtDoughnut?.data,
        backgroundColor: chartColors,
      }],
      labels: districtDoughnut?.labels,
    },
    options: {
      responsive: true,
      legend: { position: 'right' },
    }
  };
  let total = 0.0;
  let expenseData = {};
  districtBar?.data.map(category => {
    category.datasets.map(expense => {
      total += expense.amount;
      expenseData[expense.label] = {
        ...expenseData[expense.label],
        [category.labels]: expense.amount,
      }
    });
  })

  const bar = {
    data: {
      labels: budgetCategories,
      datasets: expenseCategories.map((expense, i) => ({
        label: expense,
        backgroundColor: chartColors[i % chartColors.length],
        data: budgetCategories.map(budget => expenseData[expense][budget]),
      })),
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
        }]
      },
      plugins: {
      },
    }
  }

  return (
    <div className="budget-dashboard">
      <div>
        <strong>งบประมาณสำนักเขต {total.toLocaleString('en-US', {maximumFractionDigits: 2})} บาท</strong>
        {districtDoughnut && (
          <Container className="doughnut-container">
            <Doughnut {...doughnut}/>
          </Container>
        )}
        {districtBar && (
          <Container fluid className="bar-container">
            <HorizontalBar {...bar}/>
          </Container>
        )}
      </div>
    </div>
  )
}
export default BudgetDashboard;