import React from 'react';
import { Popup } from 'react-mapbox-gl';

const WastePopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    name,
    address,
    quantity,
  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="waste popup-body">
        <h4>{name}</h4>
        {address && <div>ที่อยู่: {address}</div>}
        {quantity && <div>ปริมาณ: {quantity}</div>}
      </div>
    </Popup>
  );
}
export default WastePopup;