import React from 'react';
import Tabs from '@components/main/Tabs';
import { FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome'
import { faUsers, faDollarSign, faChartPie } from '@fortawesome/free-solid-svg-icons'

import OverviewDashboard from '@components/stats/OverviewDashboard';
import PopulationDashboard from '@components/stats/PopulationDashboard';
import BudgetDashboard from '@components/stats/BudgetDashboard';

import './Stats.scss';

const Stats = ({ district, ...props }) => {
  return (
    <Tabs tabs={[
      {
        nav: <span><FA className="icon" icon={faChartPie} />ภาพรวม</span>,
        title: `ภาพรวม : ${district?.label}`,
        content: <OverviewDashboard district={district} />
      },
      {
        nav: <span><FA className="icon" icon={faUsers} />ประชากร</span>,
        title: `ข้อมูลประชากร : ${district?.label}`,
        content: <PopulationDashboard district={district} />
      },
      {
        nav: <span><FA className="icon" icon={faDollarSign} />งบประมาณ</span>,
        title: `งบประมาณ 2563 : ${district?.label}`,
        content: <BudgetDashboard district={district} />
      },
    ]} {...props} />
  );
}

export default Stats;