import React from 'react';
import './Map.scss';

import ReactMapboxGl, { Image } from 'react-mapbox-gl';
import { icons } from '@utils/datasets';
import 'mapbox-gl/dist/mapbox-gl.css';

const { REACT_APP_MAPBOX_TOKEN } = process.env;

const Mapbox = ReactMapboxGl({
  accessToken: REACT_APP_MAPBOX_TOKEN,
});

const Map = ({ children, ...props }) => {
  return (
    <div className="map">
      <Mapbox
        style="mapbox://styles/poprvr/ck915nqtb0vtn1imjigx3g6be/draft"
        containerStyle={{ height: '100%', width: '100%' }}
        {...props}
      >
        { icons?.map(icon => (
          <Image key={icon.id} {...icon}/>
        ))}

        {children}
      </Mapbox>
    </div>
  )
}
export default Map;