import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome'
import { faExpandAlt, faCompressAlt } from '@fortawesome/free-solid-svg-icons'

import './Tabs.scss';

const Tabs = ({
  tabs,
  statsViewMode,
  setStatsViewMode,
}) => {
  const [activePane, setActivePane] = useState('nav');

  return (
    <div className="responsive-tabs" data-active-pane={activePane}>
      <Tab.Container defaultActiveKey={0}>
        <Nav variant="pills" className="nav-pane flex-column">
          { tabs?.map((tab, i) => (
            <Nav.Item>
              <Nav.Link eventKey={i} onClick={() => setActivePane('content')}>{tab.nav}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <Tab.Content className="content-pane">
          { tabs?.map((tab, i) => (
            <Tab.Pane key={i} eventKey={i}>
              <div className="stats-header">
                <div className="back-btn" onClick={() => setActivePane('nav')}>&lt; Back</div>
                <h2>{tab.title}</h2>
                <div className="nav-right">
                  { statsViewMode === 'split' ? (
                    <div className="stats-view-mode-btn" onClick={() => setStatsViewMode('full')}>
                      <FA className="icon" icon={faExpandAlt}/>
                    </div>
                  ) : (
                    <div className="stats-view-mode-btn" onClick={() => setStatsViewMode('split')}>
                      <FA className="icon" icon={faCompressAlt}/>
                    </div>
                  )}
                </div>
              </div>

              {tab.content}
            </Tab.Pane>
          ))}

        </Tab.Content>
      </Tab.Container>
    </div>
  )
}
export default Tabs;