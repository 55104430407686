import mapboxgl from 'mapbox-gl';

export const BKK_BOUNDS = [[100.32792087047036, 13.493818984605943], [100.93850886432072, 13.954595768278788]];

export const getBoundsFromCoordinates = coordinates => {
  const bounds = coordinates.reduce(
    (bounds, coord) => bounds.extend(coord),
    new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
  );
  return bounds.toArray();
}

export const getBoundsFromPolygon = polygon => {
  const coordinates = polygon[0];
  return getBoundsFromCoordinates(coordinates);
}

export const getBoundsFromFeature = feature => {
  if (feature.geometry.type === 'Polygon') {
    return getBoundsFromPolygon(feature.geometry.coordinates);
  }
  return null;
}

export const getBoundsFromMultpleFeatures = features => {
  if (features?.length === 0) return null;

  const featureBounds = features.map(feature => getBoundsFromFeature(feature));
  const bounds = featureBounds.reduce(
    (bounds, featureBound) => bounds.extend(featureBound),
    new mapboxgl.LngLatBounds(featureBounds[0][0], featureBounds[0][1])
  );
  return bounds.toArray();
}
