import React from 'react';
import { Popup } from 'react-mapbox-gl';

const CommunityPopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    // Booked Communities
    Community,
    Comm__Type,
    DensityTH,
    Population,
    Housholds,

    // Commu House & BMA Housing
    name,

    // BMA Housing
    address,
  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="community popup-body">
        <h4>{Community || name}</h4>
        {address && <div>ที่อยู่: {address}</div>}
        {Comm__Type && <div>ประเภท: {Comm__Type}</div>}
        {DensityTH && <div>ความหนาแน่น: {DensityTH}</div>}
        {Population && <div>ประชากร: {Population}</div>}
        {Housholds && <div>ครัวเรือน: {Housholds}</div>}
      </div>
    </Popup>
  );
}
export default CommunityPopup;