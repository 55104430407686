import React from 'react';
import { Popup } from 'react-mapbox-gl';

const ParkPopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    park_name: name,
    location,
    tel,
    open_close,
  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="waste popup-body">
        <h4>{name}</h4>
        {location && <div>ที่ตั้ง: {location}</div>}
        {tel && <div>โทร: {tel}</div>}
        {open_close && <div>ช่วงเวลาเปิดปิด: {open_close}</div>}
      </div>
    </Popup>
  );
}
export default ParkPopup;