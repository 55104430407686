import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { Row, Col, Button } from 'react-bootstrap';

import { GeoDatasetSections, icons } from '@utils/datasets';

import './GeoDataTypePanel.scss';

const GeoDataTypePanel = ({ onSubmit, defaultValues, ...props }) => {
  const { handleSubmit, register, getValues, setValue } = useForm({
    defaultValues,
  });

  const onCheckSection = (e, section) => {
    const geoDataType = getValues('geoDataType');
    const datasets = section.datasets.map(dataset => dataset.id);
    if (e.target.checked) {
      setValue('geoDataType', [...new Set([ ...geoDataType, ...datasets])]);
    } else {
      setValue('geoDataType', geoDataType.filter(type => datasets.indexOf(type) < 0));
    }
  }

  const onCheckType = (e, dataset, section) => {
    const sectionCheckboxName = `section.${section.id}`;
    const sectionChecked = getValues(sectionCheckboxName);
    if (e.target.checked && !sectionChecked) verifySectionCheck(section);
    else if (!e.target.checked && sectionChecked) setValue(sectionCheckboxName, false);
    
  }

  const verifySectionCheck = (section) => {
    const sectionCheckboxName = `section.${section.id}`;
    const geoDataType = getValues('geoDataType');
    const datasets = section.datasets.map(dataset => dataset.id);
    const isSubset = datasets.reduce((result, dataset) => (result && geoDataType.indexOf(dataset) >= 0), true);
    if (isSubset) setValue(sectionCheckboxName, true);
  }

  useEffect(() => {
    GeoDatasetSections.map(section => verifySectionCheck(section));
  }, []);

  return (
    <div className="geo-data-type-panel" {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-body">
          <Row>
          { GeoDatasetSections.map(section => (
            <Col md={4} className="dataset-section">
              <label className="section-label">
                <input name={`section.${section.id}`} type="checkbox" onClick={e => onCheckSection(e, section)} ref={register}/>
                <h4 className={classnames({ disabled: section.disabled })}>{section.title}</h4>
              </label>
              {section.datasets?.map(dataset => (
                <div>
                  <label className={classnames('label', { disabled: dataset.disabled })}>
                    <input name="geoDataType" type="checkbox" value={dataset.id} disabled={dataset.disabled} ref={register} onClick={e => onCheckType(e, dataset, section)}/>
                    &nbsp;<i class="icon" style={{ backgroundImage: `url(${icons.find(icon => icon.id === dataset.icon)?.url})`}}></i>
                    &nbsp;{dataset.title}
                  </label>
                </div>
              ))}
            </Col>
          ))}
          </Row>
        </div>
        <Button className="submit-btn secondary" block type="submit">ค้นหา</Button>
      </form>
    </div>
  );
}
export default GeoDataTypePanel;