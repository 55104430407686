import React, { useState } from 'react';
import classnames from 'classnames';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome'
import { faChartPie, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import Layout from '@components/main/Layout';
import DistrictField from '@components/areas/DistrictField';
import Stats from '@components/stats/Stats';
import GeoDataTypePanel from '@components/areas/GeoDataTypePanel';
import BkkDataMap from '@components/map/BkkDataMap';

import { genOverviewStats } from '@utils/datasets';

import './Home.scss';

const Home = () => {

  const [geoDataType, setGeoDataType] = useState([]);

  // Modal State
  const [statsViewMode, setStatsViewMode] = useState('split');
  const [showStats, setShowStats] = useState(false);
  const [showGeoDataTypePanel, setShowGeoDataTypePanel] = useState(false);

  // Map State
  const [selectedArea, setSelectedArea] = useState({ value: '10', label: 'กรุงเทพมหานคร' });
  
  const handleGeoDataTypeSubmit = ({ geoDataType }) => {
    setGeoDataType(geoDataType);
    setShowGeoDataTypePanel(false);
  }

  return (
    <Layout id="homepage" className={classnames({split: showStats && statsViewMode === 'split'})}>
      <BkkDataMap
        showStats={showStats}
        statsViewMode={statsViewMode}
        geoDataType={geoDataType}
        selectedArea={selectedArea}
        onSelectArea={area => setSelectedArea(area)}
      />
      <DistrictField value={selectedArea} onChange={value => setSelectedArea(value)} />
      <div className="action-panel">
        <Button className="geo-data-button" onClick={() => setShowGeoDataTypePanel(!showGeoDataTypePanel)}><FA className="icon" icon={faMapMarkerAlt} />ตำแหน่ง</Button>
        <Button className="stats-data-button" onClick={() => setShowStats(!showStats)}><FA className="icon" icon={faChartPie} />สถิติ</Button>
      </div>

      { showStats && (
        <div className="stats-panel">
          <Stats
            district={selectedArea}
            statsViewMode={statsViewMode}
            setStatsViewMode={setStatsViewMode}
          />
        </div>
      )}

      { showGeoDataTypePanel && (
        <GeoDataTypePanel onSubmit={handleGeoDataTypeSubmit} defaultValues={{ geoDataType }} />
      )}
    </Layout>
  )
}
export default Home;