import React from 'react';
import Select from 'react-select';

import districtsGeoJson from '@assets/data/districts.geo.json';

const districts = districtsGeoJson.features.map(feature => ({
  id: feature.properties.dcode,
  name: feature.properties.dname,
  name_en: feature.properties.dname_e,
}));


const DistrictField = ({ ...props }) => {
  let options = districts.map(district => ({
    value: district.id,
    label: district.name,
  }));

  options = [
    { value: '10', label: 'กรุงเทพมหานคร' },
    ...options,
  ];

  return (
    <Select options={options} defaultValue={options[0]} {...props} />
  )
}

export default DistrictField;