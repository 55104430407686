import React from 'react';
import { Popup } from 'react-mapbox-gl';

const HospitalPopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    // hospital
    name,
    address,
    tel,
    num_bed,
    out_pat,
    in_pat,

    // mhc
    num_volu,

  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="hospital popup-body">
        <h4>{name}</h4>
        {address && <div>ที่อยู่: {address}</div>}
        {tel && <div>โทร: {tel}</div>}
        {num_bed && <div>จำนวนเตียง: {num_bed}</div>}
        {out_pat && <div>ผู้ป่วยนอก: {out_pat}</div>}
        {in_pat && <div>ผู้ป่วยใน: {in_pat}</div>}
        {num_volu && <div>จำนวนอาสาสมัคร: {num_volu}</div>}
      </div>
    </Popup>
  );
}
export default HospitalPopup;