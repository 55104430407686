export const colors = {
  primary: '#76b82b',
  primaryDark: '#00823e',
  black: '#000000',
  white: '#ffffff',
}

export const chartColors = [
  '#737373',
  '#7AC36A',
  '#F05A61',
  '#CE7058',
  '#F9A75A',
  '#5A9BD4',
  '#9E66AB',
];