// District
import districtGeoJson from '@assets/data/districts.geo.json';

// Budget
import barData from '@assets/data/budget/stacked_bar_chart_data.json';

// Population
import populationJson from '@assets/data/population.json';

// Residence
import communitiesGeoJson from '@assets/data/residence/A1-communities.geo.json';
import commuHouseGeoJson from '@assets/data/residence/A2-commuhouse.geo.json';
import bmaHousingGeoJson from '@assets/data/residence/A3-bma-housing.geo.json';

// Health
import bmaHospitalGeoJson from '@assets/data/health/C1-bma-hospital.geo.json';
import govHospitalGeoJson from '@assets/data/health/C2-gov-hospital.geo.json';
import privateHospitalGeoJson from '@assets/data/health/C3-private-hospital.geo.json';
import healthCenterGeoJson from '@assets/data/health/C4-health-center.geo.json';
import bHealthGeoJson from '@assets/data/health/C5-b_health.geo.json';
import mhcGeoJson from '@assets/data/health/C6-mhc.geo.json';

// Traffic
import criticalJunctionGeoJson from '@assets/data/traffic/D1-critical_junction.geo.json';

// Flood
import floodPointGeoJson from '@assets/data/flood/E1-flood-point.geo.json';
import floodGateGeoJson from '@assets/data/flood/E2-flood-gate.geo.json';
import pondGeoJson from '@assets/data/flood/E3-pond.geo.json';
import pumpStationGeoJson from '@assets/data/flood/E4-pump-station.geo.json';
import irrWaterGateGeoJson from '@assets/data/flood/E5-IRR-water-gate.geo.json';
import canalGeoJson from '@assets/data/flood/E6-canal.geo.json';

// Waste
import wasteQuantityGeoJson from '@assets/data/waste/F1-quantity_waste.geo.json';
import wasteCenterGeoJson from '@assets/data/waste/F2-waste_center.geo.json';

// Park
import publicParkGeoJson from '@assets/data/park/G1-public_park.geo.json';

// Education
import schoolsBecGeoJson from '@assets/data/education/bec_school.geo.json';
import schoolsBmaGeoJson from '@assets/data/education/bma_school.geo.json';
import collegeGeoJson from '@assets/data/education/college.geo.json';
import universityGeoJson from '@assets/data/education/university.geo.json';
import preSchoolGeoJson from '@assets/data/education/pre_school_center.geo.json';
import daycareGeoJson from '@assets/data/education/daycare.geo.json';

// Popup
import CommunityPopup from '@components/map/CommunityPopup';
import SchoolPopup from '@components/map/SchoolPopup';
import HospitalPopup from '@components/map/HospitalPopup';
import FloodPopup from '@components/map/FloodPopup';
import WastePopup from '@components/map/WastePopup';
import ParkPopup from '@components/map/ParkPopup';
import TrafficPopup from '@components/map/TrafficPopup';

export const icons = [
  { id: 'map-marker-1', url: '/icons/map-marker-1.png' },
  { id: 'map-marker-2', url: '/icons/map-marker-2.png' },
  { id: 'map-marker-3', url: '/icons/map-marker-3.png' },
  { id: 'map-marker-4', url: '/icons/map-marker-4.png' },
  { id: 'map-marker-5', url: '/icons/map-marker-5.png' },
  { id: 'map-marker-6', url: '/icons/map-marker-6.png' },

  { id: 'ambulance-1', url: '/icons/ambulance-1.png' },
  { id: 'ambulance-2', url: '/icons/ambulance-2.png' },
  { id: 'ambulance-3', url: '/icons/ambulance-3.png' },

  { id: 'car-1', url: '/icons/car-1.png' },

  { id: 'child-1', url: '/icons/child-1.png' },
  { id: 'child-2', url: '/icons/child-2.png' },

  { id: 'graduation-cap-1', url: '/icons/graduation-cap-1.png' },
  { id: 'graduation-cap-2', url: '/icons/graduation-cap-2.png' },
  { id: 'graduation-cap-3', url: '/icons/graduation-cap-3.png' },
  { id: 'graduation-cap-4', url: '/icons/graduation-cap-4.png' },

  { id: 'home-1', url: '/icons/home-1.png' },
  { id: 'home-2', url: '/icons/home-2.png' },
  { id: 'home-3', url: '/icons/home-3.png' },

  { id: 'medkit-1', url: '/icons/medkit-1.png' },
  { id: 'medkit-2', url: '/icons/medkit-2.png' },
  { id: 'medkit-3', url: '/icons/medkit-3.png' },

  { id: 'tint-1', url: '/icons/tint-1.png' },
  { id: 'tint-2', url: '/icons/tint-2.png' },
  { id: 'tint-3', url: '/icons/tint-3.png' },
  { id: 'tint-4', url: '/icons/tint-4.png' },
  { id: 'tint-5', url: '/icons/tint-5.png' },

  { id: 'trash-1', url: '/icons/trash-1.png' },

  { id: 'tree-1', url: '/icons/tree-1.png' },
];

export const GeoDatasetSections = [
  {
    id: 'residence',
    title: 'ที่อยู่อาศัย',
    datasets: [
      {
        id: 'communities',
        title: 'ชุมชนจดทะเบียน',
        source: communitiesGeoJson,
        type: 'point',
        icon: 'home-1',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'District', selectedArea.label]: null,
        onClick: (feature, { setPopup }) => setPopup(<CommunityPopup feature={feature}/>),
      },
      {
        id: 'commuhouse',
        title: 'ชุมชนบ้านมั่นคง',
        source: commuHouseGeoJson,
        type: 'point',
        icon: 'home-2',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<CommunityPopup feature={feature}/>),
      },
      {
        id: 'bma-housing',
        title: 'อาคารสงเคราะห์ กทม',
        source: bmaHousingGeoJson,
        type: 'point',
        icon: 'home-3',
        onClick: (feature, { setPopup }) => setPopup(<CommunityPopup feature={feature}/>),
      },
    ],
  },
  {
    id: 'education',
    title: 'โรงเรียน',
    datasets: [
      {
        id: 'school-bec',
        title: 'โรงเรียน (สังกัด สพฐ)',
        source: schoolsBecGeoJson,
        type: 'point',
        icon: 'graduation-cap-1',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<SchoolPopup feature={feature}/>),
      },
      {
        id: 'school-bma',
        title: 'โรงเรียน (สังกัด กทม)',
        source: schoolsBmaGeoJson,
        type: 'point',
        icon: 'graduation-cap-2',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<SchoolPopup feature={feature}/>),
      },
      {
        id: 'college',
        title: 'วิทยาลัย',
        source: collegeGeoJson,
        type: 'point',
        icon: 'graduation-cap-3',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<SchoolPopup feature={feature}/>),
      },
      {
        id: 'university',
        title: 'มหาวิทยาลัย',
        source: universityGeoJson,
        type: 'point',
        icon: 'graduation-cap-4',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<SchoolPopup feature={feature}/>),
      },
      {
        id: 'pre-school',
        title: 'ศูนย์พัฒนาเด็กก่อนวัยเรียน',
        source: preSchoolGeoJson,
        type: 'point',
        icon: 'child-1',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<SchoolPopup feature={feature}/>),
      },
      {
        id: 'daycare',
        title: 'ศูนย์รับเลี้ยงเด็กกลางวัน',
        source: daycareGeoJson,
        type: 'point',
        icon: 'child-2',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<SchoolPopup feature={feature}/>),
      },
    ],
  },
  {
    id: 'public-health',
    title: 'สาธารณสุข',
    datasets: [
      {
        id: 'C1-bma-hospital',
        title: 'รพ. สังกัด กทม.',
        source: bmaHospitalGeoJson,
        type: 'point',
        icon: 'ambulance-1',
        onClick: (feature, { setPopup }) => setPopup(<HospitalPopup feature={feature}/>),
      },
      {
        id: 'C2-gov-hospital',
        title: 'รพ. รัฐ',
        source: govHospitalGeoJson,
        type: 'point',
        icon: 'ambulance-2',
        onClick: (feature, { setPopup }) => setPopup(<HospitalPopup feature={feature}/>),
      },
      {
        id: 'C3-private-hospital',
        title: 'รพ. เอกชน',
        source: privateHospitalGeoJson,
        type: 'point',
        icon: 'ambulance-3',
        onClick: (feature, { setPopup }) => setPopup(<HospitalPopup feature={feature}/>),
      },
      {
        id: 'C4-health-center',
        title: 'ศูนย์บริการสาธารณสุข',
        source: healthCenterGeoJson,
        type: 'point',
        icon: 'medkit-1',
        onClick: (feature, { setPopup }) => setPopup(<HospitalPopup feature={feature}/>),
      },
      {
        id: 'C5-b-health',
        title: 'ศูนย์บริการสาธารณสุขสาขา',
        source: bHealthGeoJson,
        type: 'point',
        icon: 'medkit-2',
        onClick: (feature, { setPopup }) => setPopup(<HospitalPopup feature={feature}/>),
      },
      {
        id: 'C6-mhc',
        title: 'ศูนย์สุขภาพชุมชน',
        source: mhcGeoJson,
        type: 'point',
        icon: 'medkit-3',
        show: ({ selectingDistrict }) => selectingDistrict,
        filter: ({ selectedArea }) => selectedArea ? ['==', 'dcode', selectedArea.value]: null,
        onClick: (feature, { setPopup }) => setPopup(<HospitalPopup feature={feature}/>),
      },
    ]
  },
  {
    id: 'traffic',
    title: 'การจราจร',
    datasets: [
      {
        id: 'D1-critical-junction',
        title: 'จุดจราจรวิกฤต',
        source: criticalJunctionGeoJson,
        type: 'point',
        icon: 'car-1',
        onClick: (feature, { setPopup }) => setPopup(<TrafficPopup feature={feature}/>),
      },
    ],
  },
  {
    id: 'water',
    title: 'การระบายน้ำ',
    datasets: [
      {
        id: 'E1-flood-point',
        title: 'จุดอ่อนน้ำท่วม',
        source: floodPointGeoJson,
        type: 'point',
        icon: 'tint-1',
        onClick: (feature, { setPopup }) => setPopup(<FloodPopup feature={feature}/>),
      },
      {
        id: 'E3-pond',
        title: 'บึงรับน้ำ',
        source: pondGeoJson,
        type: 'point',
        icon: 'tint-2',
        onClick: (feature, { setPopup }) => setPopup(<FloodPopup feature={feature}/>),
      },
      {
        id: 'E4-pump-station',
        title: 'สถานีปั๊มน้ำ',
        source: pumpStationGeoJson,
        type: 'point',
        icon: 'tint-3',
        onClick: (feature, { setPopup }) => setPopup(<FloodPopup feature={feature}/>),
      },
      {
        id: 'E2-bma-flood-gate',
        title: 'ประตูระบายน้ำ กทม.',
        source: floodGateGeoJson,
        type: 'point',
        icon: 'tint-4',
        onClick: (feature, { setPopup }) => setPopup(<FloodPopup feature={feature}/>),
      },
      {
        id: 'E5-irr-water-gate',
        title: 'ประตูระบายน้ำ กรมชลประทาน',
        source: irrWaterGateGeoJson,
        type: 'point',
        icon: 'tint-5',
        onClick: (feature, { setPopup }) => setPopup(<FloodPopup feature={feature}/>),
      },
      {
        id: 'E6-canal',
        title: 'คลอง',
        source: canalGeoJson,
        type: 'line',
        lineWidth: 4,
        lineColor: '#9BC0F9',
      },
    ],
  },
  {
    id: 'waste',
    title: 'การจัดการขยะ',
    datasets: [
      {
        id: 'waste-center',
        title: 'ศูนย์จำกัดขยะ',
        source: wasteCenterGeoJson,
        type: 'point',
        icon: 'trash-1',
        onClick: (feature, { setPopup }) => setPopup(<WastePopup feature={feature}/>),        
      }
    ],
  },
  {
    id: 'green',
    title: 'พื้นที่สีเขียว',
    datasets: [
      {
        id: 'public-park',
        title: 'สวนสาธารณะ',
        source: publicParkGeoJson,
        type: 'point',
        icon: 'tree-1',
        onClick: (feature, { setPopup }) => setPopup(<ParkPopup feature={feature}/>),        
      }
    ],
  },
];

export const budgetCategories = [
  'ด้านการรักษาความสะอาดและความเป็นระเบียบเรียบร้อย',
  'ด้านการบริหารทั่วไป',
  'ด้านการพัฒนาและบริการสังคม',
  'ด้านการโยธาและระบบจราจร',
  'ด้านการศึกษา',
  'ด้านการระบายน้ำและบำบัดน้ำเสีย',
  'ด้านการสาธารณสุข',
];

export const expenseCategories = [
  "เงินเดือนและค่าจ้างประจำ",
  "ค่าตอบแทน ใช้สอย และวัสดุ",
  "ค่าจ้างชั่วคราว",
  "รายจ่ายอื่น",
  "ค่าครุภัณฑ์ ท่ีดิน และสิ่งก่อสร้าง",
  "ค่าสาธารณูปโภค",
  "เงินอุดหนุน",
];

export const stackedBudgetCal = (barData) => {
  const cal = barData.map(district => {
    const budgets = {};
    district.data.foreach(category => {
      category.datasets.foreach(b => {
        if(!budgets[b.label]) budgets[b.label] = 0;
        budgets[b.label] += b.amount;
      })
    })
    return {
      district: district.district,
      labels: expenseCategories,
      data: expenseCategories.map(key => budgets[key]),
    }
  });
  return cal;
}

export const getBkkBudgetDoughnut = () => {
  let expenses = {
    "เงินเดือนและค่าจ้างประจำ": 0,
    "ค่าตอบแทน ใช้สอย และวัสดุ": 0,
    "ค่าจ้างชั่วคราว": 0,
    "รายจ่ายอื่น": 0,
    "ค่าครุภัณฑ์ ท่ีดิน และสิ่งก่อสร้าง": 0,
    "ค่าสาธารณูปโภค": 0,
    "เงินอุดหนุน": 0,
  };

  barData.forEach(district => {
    district.data.forEach(category => {
      category.datasets.forEach(expense => {
        expenses[expense.label] += expense.amount;
      })
    })
  });
  return {
    district: "กรุงเทพมหานคร",
    labels: Object.keys(expenses),
    data: Object.keys(expenses).map(expenseName => expenses[expenseName]),
  };
}

export const getBkkBudgetBar = () => {

  let categories = {};
  barData.forEach(district => {
    district.data.forEach(category => {
      if (!categories[category.labels]) categories[category.labels] = {};

      category.datasets.forEach(expense => {
        if (!categories[category.labels][expense.label]) categories[category.labels][expense.label] = 0;
        categories[category.labels][expense.label] += expense.amount;
      })
    })
  });

  return {
    district: "กรุงเทพมหานคร",
    data: Object.keys(categories).map(categoryName => ({
      labels: categoryName,
      datasets: Object.keys(categories[categoryName]).map(expenseName => ({
        label: expenseName,
        amount: categories[categoryName][expenseName],
      }))
    })),
  };
}

const getOverviewBudget = district => {
  const districtBar = barData.find(d => `เขต${d.district}` === district);
  const categories = districtBar.data.reduce((categories, category) => ({
    ...categories,
    [category.labels]: category.datasets.reduce((sum, expense) => sum + expense.amount, 0),
  }), {});

  return {
    categories,
    total: Object.keys(categories).reduce((total, category) => total + categories[category], 0),
  };
}

const getOverviewPopulation = district => {
  const districtPopulation = populationJson.find(d => d.dname === district);
  const { all_m, all_f, silent_m, silent_f, boomer_m, boomer_f, genx_m, genx_f, geny_m, geny_f, genz_votable_m, genz_votable_f, genz_unvotable_m, genz_unvotable_f, alpha_m, alpha_f } = districtPopulation;
  return {
    total: all_m + all_f,
    voter: silent_m + silent_f + boomer_m + boomer_f + genx_m + genx_f + geny_m + geny_f + genz_votable_m + genz_votable_f,
    gender: {
      male: all_m,
      female: all_f,  
    },
    age: {
      silent: silent_m + silent_f,
      boomer: boomer_m + boomer_f,
      genx: genx_m + genx_f,
      geny: geny_m + geny_f,
      genz: genz_votable_m + genz_votable_f + genz_unvotable_m + genz_unvotable_f,
      alpha: alpha_m + alpha_f,
    }
  }
}

export const genBkkOverviewStats = districts => {
  return {
    dcode: "10",
    dname: "กรุงเทพมหานคร",
    area: districts.reduce((sum, d) => sum + d.area, 0),
    population: {
      total: districts.reduce((sum, d) => sum + d.population.total, 0),
      voter: districts.reduce((sum, d) => sum + d.population.voter, 0),
      gender: {
        male: districts.reduce((sum, d) => sum + d.population.gender.male, 0),
        female: districts.reduce((sum, d) => sum + d.population.gender.female, 0),
      },
      age: {
        silent: districts.reduce((sum, d) => sum + d.population.age.silent, 0),
        boomer: districts.reduce((sum, d) => sum + d.population.age.boomer, 0),
        genx: districts.reduce((sum, d) => sum + d.population.age.genx, 0),
        geny: districts.reduce((sum, d) => sum + d.population.age.geny, 0),
        genz: districts.reduce((sum, d) => sum + d.population.age.genz, 0),
        alpha: districts.reduce((sum, d) => sum + d.population.age.alpha, 0),
      },
    },
    budget: {
      total: districts.reduce((sum, d) => sum + d.budget.total, 0),
      categories: {
        "ด้านการบริหารทั่วไป": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการบริหารทั่วไป'], 0),
        "ด้านการพัฒนาและบริการสังคม": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการพัฒนาและบริการสังคม'], 0),
        "ด้านการระบายน้ำและบำบัดน้ำเสีย": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการระบายน้ำและบำบัดน้ำเสีย'], 0),
        "ด้านการรักษาความสะอาดและความเป็นระเบียบเรียบร้อย": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการรักษาความสะอาดและความเป็นระเบียบเรียบร้อย'], 0),
        "ด้านการศึกษา": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการศึกษา'], 0),
        "ด้านการสาธารณสุข": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการสาธารณสุข'], 0),
        "ด้านการโยธาและระบบจราจร": districts.reduce((sum, d) => sum + d.budget.categories['ด้านการโยธาและระบบจราจร'], 0),
      },
    },
    residence: {
      communities:  districts.reduce((sum, d) => sum + d.residence.communities, 0),
      commuhouses:  districts.reduce((sum, d) => sum + d.residence.commuhouses, 0),
      bmaHousing:   districts.reduce((sum, d) => sum + d.residence.bmaHousing, 0),
    },
    education: {
      daycare:    districts.reduce((sum, d) => sum + d.education.daycare, 0),
      preSchool:  districts.reduce((sum, d) => sum + d.education.preSchool, 0),
      bmaSchool:  districts.reduce((sum, d) => sum + d.education.bmaSchool, 0),
      becSchool:  districts.reduce((sum, d) => sum + d.education.becSchool, 0),
      college:    districts.reduce((sum, d) => sum + d.education.college, 0),
      university: districts.reduce((sum, d) => sum + d.education.university, 0),
    },
    health: {
      bmaHospital:        districts.reduce((sum, d) => sum + d.health.bmaHospital, 0),
      govHospital:        districts.reduce((sum, d) => sum + d.health.govHospital, 0),
      privateHospital:    districts.reduce((sum, d) => sum + d.health.privateHospital, 0),
      healthCenter:       districts.reduce((sum, d) => sum + d.health.healthCenter, 0),
      healthCenterBranch: districts.reduce((sum, d) => sum + d.health.healthCenterBranch, 0),
      mhc:                districts.reduce((sum, d) => sum + d.health.mhc, 0),
    },
    traffic: {
      critical:     districts.reduce((sum, d) => sum + d.traffic.critical, 0),
    },
    water: {
      floodPoint:   districts.reduce((sum, d) => sum + d.water.floodPoint, 0),
      pond:         districts.reduce((sum, d) => sum + d.water.pond, 0),
      pondVolume:   districts.reduce((sum, d) => sum + d.water.pondVolume, 0),
      pumpStation:  districts.reduce((sum, d) => sum + d.water.pumpStation, 0),
      gate:         districts.reduce((sum, d) => sum + d.water.gate, 0),
    },
    waste: {
      quantity: {
        '2554': districts.reduce((sum, d) => sum + d.waste.quantity['2554'], 0.0),
        '2555': districts.reduce((sum, d) => sum + d.waste.quantity['2555'], 0.0),
        '2556': districts.reduce((sum, d) => sum + d.waste.quantity['2556'], 0.0),
      }
    },
    park: {
      park: districts.reduce((sum, d) => sum + d.park.park, 0),
    },
  }
}

export const genOverviewStats = () => {
  let result = districtGeoJson.features.reduce((result, feature) => ({ ...result, [feature.properties.dname]: feature.properties }), {});

  result = Object.keys(result).reduce((sum, district) => ({
    ...sum,
    [district]: {
      dcode:      result[district].dcode,
      dname:      result[district].dname,
      dname_e:    result[district].dname_e,
      area:       result[district].AREA,
      population: getOverviewPopulation(district),
      budget:     getOverviewBudget(district),
      residence: {
        communities:  communitiesGeoJson.features.filter(feature => feature.properties.District === district)?.length,
        commuhouses:  commuHouseGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        bmaHousing:   bmaHousingGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
      },
      education: {
        daycare:    daycareGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        preSchool:  preSchoolGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        bmaSchool:  schoolsBmaGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        becSchool:  schoolsBecGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        college:    collegeGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        university: universityGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
      },
      health: {
        bmaHospital:        bmaHospitalGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        govHospital:        govHospitalGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        privateHospital:    privateHospitalGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        healthCenter:       healthCenterGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        healthCenterBranch: bHealthGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        mhc:                mhcGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
      },
      traffic: {
        critical: criticalJunctionGeoJson.features.filter(feature => feature.properties.DCODE === result[district].dcode)?.length,
      },
      water: {
        floodPoint:   floodPointGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        pond:         pondGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        pondVolume:   pondGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.reduce((sum, feature) => sum + feature.properties?.volume, 0),
        pumpStation:  pumpStationGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
        gate:         floodGateGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
      },
      waste: {
        quantity: {
          '2554': wasteQuantityGeoJson.features.find(feature => feature.properties.dcode === result[district].dcode)?.properties['2554'],
          '2555': wasteQuantityGeoJson.features.find(feature => feature.properties.dcode === result[district].dcode)?.properties['2555'],
          '2556': wasteQuantityGeoJson.features.find(feature => feature.properties.dcode === result[district].dcode)?.properties['2556'],
        }
      },
      park: {
        park: publicParkGeoJson.features.filter(feature => feature.properties.dcode === result[district].dcode)?.length,
      },
    }
  }), {});

  result = Object.keys(result).map(district => result[district]);
  const bkk = genBkkOverviewStats(result);
  result = [
    bkk,
    ...result,
  ]

  return result;
}

