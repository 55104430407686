import React from 'react';
import { Popup } from 'react-mapbox-gl';

const FloodPopup = ({ feature }) => {
  if (!feature) return null;
  
  const {
    name,
    location,

    // Pond
    volume,
    solve_area,
    agencies,

    // Pump Station
    capacity,
    function: stationFunction,

    // Gate
    size,

    // IRR Water Gate
    department,

  } = feature?.properties;
  return (
    <Popup
      coordinates={feature?.geometry?.coordinates}
      anchor="left"
      offset={10}
    >
      <div className="flood popup-body">
        <h4>{name}</h4>
        {location && <div>ตำแหน่ง: {location}</div>}
        {size && <div>ขนาด: {size}</div>}
        {volume && <div>ความจุ: {volume} ลบ.ม.</div>}
        {solve_area && <div>พื้นที่รองรับ: {solve_area}</div>}
        {agencies && <div>เจ้าของ: {agencies}</div>}
        {capacity && <div>อัตราการสูบน้ำ: {capacity}</div>}
        {stationFunction && <div>การทำงาน: {stationFunction}</div>}
        {department && <div>หน่วยงาน: {department}</div>}
      </div>
    </Popup>
  );
}
export default FloodPopup;