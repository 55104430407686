import React from 'react';
import './Topnav.scss';
import logo from '@assets/icons/logo-white.svg';

const Topnav = ({ children }) => {
  return (
    <div id="topnav">
      <img className="logo" src={logo} />
    </div>
  );
};
export default Topnav;