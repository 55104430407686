import React, { Fragment, useState, useEffect } from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';

import Map from '@components/map/Map';
import DistrictLayer from '@components/map/DistrictLayer';
import PointLayer from '@components/map/PointLayer';
import LineLayer from '@components/map/LineLayer';

import { colors } from '@utils/colors';
import { BKK_BOUNDS, getBoundsFromFeature } from '@utils/map';
import { GeoDatasetSections } from '@utils/datasets';
import districtsGeoJson from '@assets/data/districts.geo.json';

const BkkDataMap = ({
  showStats,
  statsViewMode,
  geoDataType,
  selectedArea,
  onSelectArea,
}) => {
  const [areas, setAreas] = useState(null);
  const [popup, setPopup] = useState(null);
  const [bounds, setBounds] = useState(BKK_BOUNDS);

  const selectingDistrict = () => selectedArea?.value?.length > 2;

  useEffect(() => {
    setAreas(districtsGeoJson);
  }, []);

  useEffect(() => {
    setPopup(null);
    if (selectingDistrict()) {
      const area = districtsGeoJson.features.find(feature => feature.properties.dcode === selectedArea.value);
      // District
      if (area) {
        setAreas({
          ...districtsGeoJson,
          name: 'selectedAreas',
          features: [area],
        });

        setBounds(getBoundsFromFeature(area));
      }
    } else {
      // Province
      setAreas(null);
      setBounds(BKK_BOUNDS);
    }
  }, [selectedArea]);

  let districtTextField = '{dname_s}';  

  return (
    <Map
      fitBounds={bounds}
      fitBoundsOptions={{ padding: { top: 100, bottom: 50, left: 50, right: statsViewMode === 'split' && showStats ? (window.innerWidth / 2) + 50 : 50 }, offset: [0, 0]}}
      onClick={() => setPopup(null)}
    >
      <DistrictLayer
        textField={districtTextField}
        onSelectDistrict={feature => {
          if (selectedArea?.value !== feature.properties.dcode) {
            if (onSelectArea) onSelectArea({ value: feature.properties.dcode, label: feature.properties.dname });
          }
        }}
      />

      { GeoDatasetSections.filter(section => !section.disabled).map(section => section.datasets?.filter(dataset => !dataset.disabled).map(dataset => {
        switch (dataset.type) {
          case 'point':
            return <PointLayer
              {...dataset}
              key={dataset.id}
              show={geoDataType?.indexOf(dataset.id) >= 0 && (dataset.show ? dataset.show({ selectingDistrict: selectingDistrict() }) : true) }
              filter={dataset.filter && dataset.filter({ selectedArea })}
              onClick={feature => dataset.onClick && dataset.onClick(feature, { setPopup })}
            />;
          case 'line':
            return <LineLayer
              {...dataset}
              key={dataset.id}
              show={geoDataType?.indexOf(dataset.id) >= 0 && (dataset.show ? dataset.show({ selectingDistrict: selectingDistrict() }) : true) }
              filter={dataset.filter && dataset.filter({ selectedArea })}
            />
          default: return null;
        }
      }))}

      { areas && (
        <Fragment>
          <GeoJSONLayer
            data={areas}
            fillPaint={{
              "fill-color": colors.primary,
              "fill-opacity": 0.2,
            }}
            linePaint={{
              "line-color": colors.primary,
              "line-width": 1,
            }}
            symbolLayout={{
              "text-offset": [0, 0.6],
              "text-anchor": "top"
            }}
          />
        </Fragment>
      )}

      { popup }
    </Map>
  );
};
export default BkkDataMap;