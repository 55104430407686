import React, { Fragment, useState } from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { colors } from '@utils/colors';
import districtsGeoJson from '@assets/data/districts.geo.json';

const DistrictLayer = ({
  onSelectDistrict,
  textField,
  highlightTextField,
}) => {
  const [hoverFeatures, setHoverFeatures] = useState('');

  return (
    <Fragment>
      <Source id="districts" geoJsonSource={{ type: 'geojson', data: districtsGeoJson, promoteId: 'dcode' }}/>
      <Layer
        id="districts-line"
        type="line"
        sourceId="districts"
        paint={{
          "line-color": '#888',
          "line-width": 1,
          "line-opacity": 0.2
        }}
      />
      <Layer
        id="districts-fill"
        type="fill"
        sourceId="districts"
        paint={{
          "fill-color": '#888',
          "fill-opacity": 0.2
        }}
        onMouseMove={e => {
          const map = e.target;
          const features = map.queryRenderedFeatures(e.point, { layers: ['districts-fill'] });
          if (features.length > 0) {
            setHoverFeatures(features[0].properties.dcode);
          }
        }}
        onMouseLeave={() => {
          setHoverFeatures('');
        }}
      />
      <Layer
        id="districts-highlight"
        type="fill"
        sourceId="districts"
        paint={{
          "fill-color": colors.primary,
          "fill-opacity": 0.15,
        }}
        filter={['==', 'dcode', hoverFeatures]}
        onClick={e => {
          const map = e.target;
          const features = map.queryRenderedFeatures(e.point, { layers: ['districts-fill'] });
          if (features.length > 0) {
            if (onSelectDistrict) onSelectDistrict(features[0]);
          }
        }}
      />
      <Layer
        id="districts-highlight-text"
        type="symbol"
        sourceId="districts"
        layout={highlightTextField ? {
          "text-field": highlightTextField,
          "text-size": 12,
          "text-allow-overlap": true
        }: {}}
        paint={{
          "text-translate": [0, -15],
        }}
        filter={['==', 'dcode', hoverFeatures]}
      />
      <Layer
        id="districts-symbol"
        type="symbol"
        sourceId="districts"
        layout={!!textField ? {
          "text-field": textField,
          "text-size": 12,
          "text-allow-overlap": true,
        } : {}}
      />
    </Fragment>
  );
}
export default DistrictLayer;