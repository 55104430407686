import React from 'react';

import districts from '@assets/data/overview.json';

const OverviewDashboard = ({ district }) => {

  const d = districts.find(d => district.label === d.dname);

  if (!d) return null;

  return (
    <div className="overview-dashboard">
      <h4>ประชากรและพื้นที่</h4>
      <ul>
        <li>ประชากรในเขต: {d.population?.total?.toLocaleString('en-US', {maximumFractionDigits: 0})} คน</li>
        <li>ผู้มีสิทธิเลือกตั้ง: {d.population?.voter?.toLocaleString('en-US', {maximumFractionDigits: 0})} คน</li>
        <li>พื้นที่: {(d.area / 1000000).toLocaleString('en-US', {maximumFractionDigits: 1})} ตารางกิโลเมตร</li>
        <li>ความหนาแน่นประชากร: {(d.population?.total / (d.area / 1000000)).toLocaleString('en-US', {maximumFractionDigits: 0})} คนต่อตารางกิโลเมตร</li>
      </ul>

      <h4>งบประมาณ (ปี 2563)</h4>
      <ul>
        <li>งบประมาณเขต: {d.budget?.total?.toLocaleString('en-US', {maximumFractionDigits: 0})} บาท</li>
        <li>งบประมาณต่อคน: {(d.budget?.total / d.population?.total).toLocaleString('en-US', {maximumFractionDigits: 0})} บาทต่อคน</li>
      </ul>

      <h4>ที่อยู่อาศัย</h4>
      <ul>
        <li>ชุมชนจดทะเบียน: {d.residence?.communities}</li>
        <li>ชุมชนบ้านมั่นคง: {d.residence?.commuhouses}</li>
        <li>อาคารสงเคราะห์: {d.residence?.bmaHousing}</li>
      </ul>

      <h4>การศึกษา</h4>
      <ul>
        <li>งบประมาณด้านการศึกษา: {d.budget?.categories['ด้านการศึกษา']?.toLocaleString('en-US', {maximumFractionDigits: 0})} บาท</li>
        <li>โรงเรียน กทม: {d.education?.bmaSchool}</li>
        <li>โรงเรียน สพฐ: {d.education?.becSchool}</li>
        <li>วิทยาลัย: {d.education?.college}</li>
        <li>มหาวิทยาลัย: {d.education?.university}</li>
        <li>ศูนย์พัฒนาเด็กก่อนวัยเรียน: {d.education?.preSchool}</li>
        <li>ศูนย์รับเลี้ยงเด็กกลางวัน: {d.education?.daycare}</li>
      </ul>

      <h4>สาธารณสุข</h4>
      <ul>
        <li>งบประมาณด้านสาธารณสุข: {d.budget?.categories['ด้านการสาธารณสุข']?.toLocaleString('en-US', {maximumFractionDigits: 0})} บาท</li>
        <li>งบประมาณด้านสาธารณสุขต่อคน: {(d.budget?.categories['ด้านการสาธารณสุข'] / d.population.total).toLocaleString('en-US', {maximumFractionDigits: 2})}</li>
        <li>โรงพยาบาล กทม: {d.health?.bmaHospital}</li>
        <li>โรงพยาบาล รัฐ: {d.health?.govHospital}</li>
        <li>โรงพยาบาล เอกชน: {d.health?.privateHospital}</li>
        <li>ศูนย์บริการสาธารณสุข: {d.health?.healthCenter}</li>
        <li>ศูนย์บริการสาธารณสุขสาขา: {d.health?.healthCenterBranch}</li>
        <li>ศูนย์สุขภาพชุมชน: {d.health?.mhc}</li>
      </ul>

      <h4>จราจร</h4>
      <ul>
        <li>จุดจราจรวิกฤต: {d.traffic?.critical} จุด</li>
      </ul>

      <h4>การระบายน้ำ</h4>
      <ul>
        <li>งบประมาณด้านการระบายน้ำและบำบัดน้ำเสีย: {d.budget?.categories['ด้านการระบายน้ำและบำบัดน้ำเสีย']?.toLocaleString('en-US', {maximumFractionDigits: 0})} บาท</li>
        <li>จุดอ่อนน้ำท่วม: {d.water?.floodPoint}</li>
        <li>บึงรับน้ำ: {d.water?.pond}</li>
        <li>ความจุบึงรับน้ำ: {d.water?.pondVolume?.toLocaleString('en-US', {maximumFractionDigits: 0})}</li>
        <li>สถานีปั๊มน้ำ: {d.water?.pumpStation}</li>
        <li>ประตูระบายน้ำ: {d.water?.gate}</li>
      </ul>

      <h4>ความสะอาด</h4>
      <ul>
        <li>งบประมาณด้านการรักษาความสะอาด: {d.budget?.categories['ด้านการรักษาความสะอาดและความเป็นระเบียบเรียบร้อย']?.toLocaleString('en-US', {maximumFractionDigits: 0})} บาท</li>
        <li>ปริมาณขยะ ปี 2556: {d.waste?.quantity['2556'].toLocaleString('en-US', {maximumFractionDigits: 0})}</li>
      </ul>

      <h4>พื้นที่สีเขียว</h4>
      <ul>
        <li>สวนสาธารณะ: {d.park?.park}</li>
      </ul>
    </div>
  );
}

export default OverviewDashboard;
