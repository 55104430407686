import React from 'react';
import Topnav from '@components/main/Topnav';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Layout.scss';

const Layout = ({ children, ...props }) => {
  return (
    <div {...props}>
      <Topnav />
      <main>
        {children}
      </main>
    </div>
  );
};
export default Layout;